import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import Cookies from 'js-cookie';
// @ts-ignore
import fetch from 'react-storefront/fetch';
import UserStore from './UserStore';
import { SalesChannel as SC, SalesChannelContext, ShopwareCountry } from '@services/types/Shopware';

interface SalesChannel extends SC {
    id: string;
    translated: {
        countryId: string;
        currencyId: string;
        customerGroupId: string;
        footerCategoryId: string;
        footerCategoryVersionId: string;
        hreflangDefaultDomainId: string;
        languageId: string;
        mailHeaderFooterId: string;
        name: string;
        navigationCategoryId: string;
        navigationCategoryVersionId: string;
        paymentMethodId: string;
        serviceCategoryId: string;
        serviceCategoryVersionId: string;
        shippingMethodId: string;
        shortName: string;
        taxCalculationType: string;
        customFields: {
            toplinks_outlet_text: string;
            toplinks_outlet_link: string;
            toplinks_campaign_text: string;
            toplinks_campaign_link: string;
            toplinks_customerservice_text: string;
            toplinks_customerservice_link: string;
        };
    };
}

interface LanguageConfig {
    locale: string;
    languageCode: string;
}

interface SessionConfig {
    language: LanguageConfig;
    currency: string;
    loop54Feed: string;
}

interface SessionState extends SalesChannelContext {
    salesChannel: SalesChannel;
    currentLanguage: Partial<LanguageConfig>;
    config: SessionConfig;
    geoIpCountry: string | boolean;
    countries: ShopwareCountry[];
    filters: string[];
    extensions: any;
}

interface SessionMethods {
    getEclubData: () => {
        id?: string;
        email?: string;
        dateTime: string;
        contactId?: string;
        discoverKey?: string;
    } | null;
    showExpressCheckout: () => boolean;
    getSessionCookie: () => string | undefined;
    getCurrency: () => string;
    getLanguageCode: () => string;
    getCountries: () => Promise<ShopwareCountry[]>;
    setSession: (session: Partial<SessionState>) => void;
    getSalesChannel: () => SalesChannel;
    isSalesChannelByDomain: (domain: string) => boolean;
    isSalesChannelNordic: () => boolean;
    formatNumber: (value: number) => string;
    formatPrice: (amount: number) => string | number;
    getCurrencySymbol: () => string;
}

interface StoreState {
    init: boolean;
    session: SessionState;
    methods: SessionMethods;
}

const currencyFormatters: {
    [key: string]: any
} = {};

const currencySymbols: {
    [key: string]: string
} = {};

const initialState = {
    token: false,
    salesChannel: {
        id: null,
        translated: {
            customFields: {
                toplinks_outlet_text: '',
                toplinks_outlet_link: '',
                toplinks_campaign_text: '',
                toplinks_campaign_link: '',
                toplinks_customerservice_text: '',
                toplinks_customerservice_link: ''
            }
        }
    },
    currentLanguage: {},
    currency: {},
    config: {
        language: {
            locale: "se",
            languageCode: 'sv-SE'
        },
        currency: 'SEK',
        loop54Feed: "https://newport-se-dev.54proxy.com"
    },
    geoIpCountry: false,
    countries: [],
    filters: process.env.NEXT_PUBLIC_STOREFRONT_FILTER_ATTRIBUTES ? process.env.NEXT_PUBLIC_STOREFRONT_FILTER_ATTRIBUTES.split(',') : [],
    shippingLocation: null,
    paymentMethod: null,
    paymentMethodId: null,
    shippingMethod: null,
    shippingMethodId: null,
};

const useSessionStore = create<StoreState>()(
    persist(
        (set, get) => ({
            init: false,
            // @ts-ignore @todo fixifix
            session: initialState,
            methods: {
                getEclubData() {
                    const user = UserStore.getState()?.user;

                    if (user?.voyado?.discovery_key) {
                        return {
                            id: user.member_number,
                            email: user.email,
                            dateTime: (new Date()).toISOString(),
                            contactId: user.voyado_id,
                            discoverKey: user?.voyado?.discovery_key,
                        };
                    }

                    if (typeof localStorage !== 'object') {
                        return null;
                    }

                    // @ts-ignore
                    return localStorage.getItem('eclubData') ? JSON.parse(localStorage.getItem('eclubData')) : null;
                },
                showExpressCheckout() {
                    return false //Temp hide inb4 bugfix with shippingmethods  //get().session.paymentMethod?.translated?.name !== 'Klarna Checkout';
                },
                getSessionCookie() {
                    return Cookies.get(process.env.NEXT_PUBLIC_STOREFRONT_SESSION_COOKIE || '');
                },
                getCurrency() {
                    return get()?.session?.currency?.isoCode || get().session.config?.currency;
                },
                getLanguageCode() {
                    return get().session.currentLanguage.languageCode || get().session.config.language.languageCode;
                },
                async getCountries() {
                    if (get().session.countries.length > 0) {
                        return get().session.countries;
                    }

                    const response = await fetch('/api/countries/get', {
                        method: 'get',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                    });

                    const result = await response.json();

                    if (result.status === 'success') {
                        console.log('getCountries');
                        get().methods.setSession({ countries: result.countries });
                        return get().session.countries;
                    } else {
                        console.error(result);
                        throw new Error('An unknown error occurred while attempting to fetch countries.');
                    }
                },
                setSession(session) {
                    set((state) => {
                        return {init: true, session: { ...state.session, ...session }};
                    });
                },
                getSalesChannel() {
                    return get().session.salesChannel;
                },
                isSalesChannelByDomain(domain) {
                    const tld = domain.toLowerCase();
                    const id = get().session.salesChannel.id;

                    if (tld === 'se' && id === '7e45b833887f47889f551bed32011d82') {
                        return true
                    }

                    if (tld === 'eu' && id === '369471b7efe746eab4527b3a0d7e49af') {
                        return true
                    }

                    return false;
                },
                isSalesChannelNordic() {
                    const config = get().session.config;
                    return config.language.locale === 'sv'
                        || config.language.locale === 'nb'
                        || config.language.locale === 'dk'
                        || config.language.locale === 'fi';
                },
                formatNumber(value) {
                    const session = get().session;
                    const languageCode = typeof session.currentLanguage.languageCode !== 'undefined' ? session.currentLanguage.languageCode : session.config.language.languageCode;

                    const formatter = new Intl.NumberFormat(languageCode,{
                        style: 'decimal',
                        minimumFractionDigits: isFraction(value) ? 1 : 0,
                        maximumFractionDigits: 2
                    });

                    function isFraction(number: number) {
                        return number % 1 !== 0;
                    }

                    return formatter.format(value);
                },
                formatPrice(amount) {
                    const actions = get().methods;
                    const currency = actions.getCurrency();
                    const languageCode = actions.getLanguageCode();

                    if (!currency) {
                        return amount;
                    }

                    function isFraction(number: number) {
                        if (currency === 'USD') {
                            return true
                        }
                        return number % 1 !== 0;
                    }

                    let amountFraction = 0;
                    if (isFraction(amount)) {
                        if (currency === 'USD') {
                            amountFraction = 2
                        } else {
                            amountFraction = 1;
                        }
                    }

                    const cacheKey = `${currency}-${languageCode}-${amountFraction}`;
                    if (currencyFormatters[cacheKey]) {
                        return currencyFormatters[cacheKey].format(amount);
                    }

                    const formatter = Intl.NumberFormat(languageCode, {
                        style: 'currency',
                        currency: currency,
                        minimumFractionDigits: amountFraction,
                        maximumFractionDigits: 2
                    });

                    currencyFormatters[cacheKey] = formatter;
                    return formatter.format(amount);
                },
                getCurrencySymbol() {
                    const session = get().session;
                    const languageCode = session.config.language.languageCode;
                    const currency = typeof session?.currency?.isoCode !== 'undefined' ? session.currency.isoCode : session.config.currency;

                    if (!currency) {
                        return '€';
                    }

                    const cacheKey = `${currency}`;
                    if (currencySymbols[cacheKey]) {
                        return currencySymbols[cacheKey];
                    }

                    const symbol = new Intl.NumberFormat(languageCode, { style: 'currency', currency })
                        ?.formatToParts(1)
                        ?.find(x => x.type === "currency")
                        ?.value;

                    if (!symbol) {
                        return '';
                    }

                    currencySymbols[cacheKey] = symbol;
                    return symbol;
                },
            },
        }),
        {
            name: 'session-storage',
            partialize: (state) => ({
                session: state.session,
            }),
        },
    ),
);

export default useSessionStore;
